import React, { useEffect, useState } from "react";


import ReactDOM from "react-dom";
import { ApolloProvider} from "@apollo/react-hooks";
import { createStore, applyMiddleware } from "redux";
import { Provider, useDispatch, useSelector} from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"; 

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
 import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import RootReducer from "./RootReducer";

import apolloClient from "./apolloClient";


import Modal from "react-modal";
import { AliveScope } from "react-activation";

import "./style.scss"
import AdminRouter from "./components/admin/AdminRouter";


import ACTION_USER_AUTHENTICATED from "./actions/user/Authenticated/ACTION_USER_AUTHENTICATED";
import ACTION_USER_NOT_AUTHENTICATED from "./actions/user/Authenticated/ACTION_USER_NOT_AUTHENTICATED";
import ACTION_DUMMY_TOKEN from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN";
import ACTION_DUMMY_TOKEN_RESET from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN_RESET";
//import ACTION_DUMMY_TOKEN_RESET from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN_RESET";
import ACTION_TEMPORARY_DUMMY_TOKEN from "./actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN";
import ACTION_TEMPORARY_DUMMY_TOKEN_RESET from "./actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN_RESET";
// loading spinner

import ACTION_LOADING_SPINNER_ACTIVE from "./actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "./actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";

import getUserQuery from "./graphql/queries/user/getUserQuery"

import Cookies, { set } from "js-cookie";
import jwt from "jsonwebtoken";


import Spinner from "./components/resuable/spinner" 
import updateUserInvalidateTokensMutation from "./graphql/mutations/user/updateUserInvalidateTokensMutation";
import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
import Home from "./components/Home";
import AboutUs from "./components/Home/aboutUs";
import GoalOne from "./components/Home/goals/one";
import GoalTwo from "./components/Home/goals/two";
import GoalThree from "./components/Home/goals/three";
import GoalFour from "./components/Home/goals/four";
import GoalFive from "./components/Home/goals/five";
import GoalSix from "./components/Home/goals/six";
import Results from "./components/Home/results";
import Faqs from "./components/Home/faqs";
import Pricing from "./components/Home/pricing";
import Contact from "./components/Home/contact";
import UserForm from "./components/Home/form";
import { ThankYou } from "./components/Home/form/thankyou";
import { AdminCampaignGift } from "./components/admin/adminCampaignGift";
const middleware = [thunk];

const store = createStore( 
  RootReducer,
  process.env.REACT_APP_NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware))
);

const App = () => {

  const history = useHistory()
  const dispatch = useDispatch();
  const location = useLocation();
  const userAuthenticated = useSelector(
    (state) => state.userAuthenticated.user_authenticated
  );

  const dummyToken = useSelector((state) => state.dummyToken.dummy_token);
  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);

  useEffect(() => {
    let currentDummyToken;
    let temporaryUserDummyToken;
    const checkCookies = () => {

      if (
        currentDummyToken !== Cookies.get("dummy-token") ||
        temporaryUserDummyToken !== Cookies.get("temporary-dummy-token")
      ) {
        currentDummyToken = Cookies.get("dummy-token");
        temporaryUserDummyToken = Cookies.get("temporary-dummy-token");

        if (currentDummyToken) {
          if (!dummyToken) {
            dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET());
            dispatch(ACTION_DUMMY_TOKEN(jwt.decode(currentDummyToken)));
          }
          if (!userAuthenticated) {
             dispatch(ACTION_USER_AUTHENTICATED());
          }
        } else if (temporaryUserDummyToken) {
          if (temporaryUserDummyToken) {
            if (!temporaryDummyToken) {
              dispatch(
                ACTION_TEMPORARY_DUMMY_TOKEN(
                  jwt.decode(temporaryUserDummyToken)
                )
              );
            }
            if (userAuthenticated) {
              dispatch(ACTION_USER_NOT_AUTHENTICATED());
            }
          } else {
            if (temporaryDummyToken) {
              dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET());
            }

            if (userAuthenticated) {
              dispatch(ACTION_USER_NOT_AUTHENTICATED());
            }
          }
        }
      }
    };

    setInterval(checkCookies, 100);
  }, [
    dispatch,
    location.pathname,
    userAuthenticated,
    dummyToken,
    temporaryDummyToken
  ]);

  const [loggingOut, changeLoggingOut] = useState(false);

  
  const [
    updateUserInvalidateTokens,
    { data: userLogoutData },
  ] = useMutation(updateUserInvalidateTokensMutation);

  
useEffect(() => {
  if (userLogoutData) {
    if (loggingOut) {
      if (userAuthenticated) {
        dispatch(ACTION_USER_NOT_AUTHENTICATED());
      }
      if (dummyToken) {
        dispatch(ACTION_DUMMY_TOKEN_RESET());
      }
      changeLoggingOut(false);
    }
  }
}, [userLogoutData, dispatch, userAuthenticated, loggingOut, dummyToken]);

  const handleLogout = () => {
    updateUserInvalidateTokens();
    changeLoggingOut(true);
  
};

    const {
    data: getUserData,
    error: getUserError,
    loading: getUserLoading,
    refetch: userDataRefetch,
    subscribeToMore: userSubscribeToMore,
  } = useQuery(getUserQuery, {
    variables: {
      _id: temporaryDummyToken
        ? temporaryDummyToken.id
        : dummyToken
        ? dummyToken.id
        : null,
    },
  });


  useEffect(() => {
    if(userAuthenticated) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[
    dispatch,
    userAuthenticated
  ])

  useEffect(() => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(!Cookies.get("dummy-token")) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[])

  return ( 

    <div className="root bcg">
      <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Spinner isOpen={ false && userAuthenticated && ( !getUserData ) } />

      <Switch>
        <Route 
        exact path="/"
          render={() => (
            <Redirect to="/home" />
          )}
        />


        <Route 
        exact 
        path="/home"
        render={() => (
          <Home />
        )} 
        />

        <Route 
        exact 
        path="/form-ems-egypt"
        render={() => (
          <UserForm />
        )} 
        />

<Route 
        exact 
        path="/thanks"
        render={() => (
          <ThankYou />
        )} 
        />




<Route
          exact
          path={"/newcampaign/:id"}
          render={({match}) => (
            <AdminCampaignGift param={match.params.id}/>
          )}
        />

<Route
          exact
          path={"/newcampaign/:id/:idd"}
          render={({match}) => (
            <AdminCampaignGift param={`${match.params.id}/${match.params.idd}`}/>
          )}
        />

<Route
          exact
          path={"/newcampaign/:id/:idd/:iddd"}
          render={({match}) => (
            <AdminCampaignGift param={`${match.params.id}/${match.params.idd}/${match.params.iddd}`}/>
          )}
        />
        <Route
          exact
          path={"/newcampaign/:id/:idd/:iddd/:idddd"}
          render={({match}) => (
            <AdminCampaignGift param={`${match.params.id}/${match.params.idd}/${match.params.iddd}/${match.params.idddd}`}/>
          )}
        />




      <Route 
        exact 
        path="/aboutus"
        render={() => (
          <AboutUs />
        )} 
        />
        <Route 
        exact 
        path="/goal-one"
        render={() => (
          <GoalOne />
        )} 
        />
        <Route 
        exact 
        path="/goal-two"
        render={() => (
          <GoalTwo /> 
        )} 
        />
        <Route 
        exact 
        path="/goal-three"
        render={() => (
          <GoalThree />
        )} 
        />
         <Route 
        exact 
        path="/goal-four"
        render={() => (
          <GoalFour />
        )} 
        />
      <Route 
        exact 
        path="/goal-five"
        render={() => (
          <GoalFive />
        )} 
        />
         <Route 
        exact 
        path="/goal-six"
        render={() => (
          <GoalSix />
        )} 
        />
        <Route 
        exact 
        path="/results"
        render={() => (
          <Results />
        )} 
        />
         <Route 
        exact 
        path="/faqs"
        render={() => (
          <Faqs />
        )} 
        />
         <Route 
        exact 
        path="/pricing"
        render={() => (
          <Pricing />
        )} 
        />

        <Route 
        exact 
        path="/contact"
        render={() => (
          <Contact />
        )} 
        />

            <Route
            path="/admin"
              render={() => (
                <AdminRouter
                handleLogout={handleLogout}
                  path="/admin"
                  user={getUserData}
                />
              )}
            />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      </div>
        </div>
  );
};

const rootElement = document.getElementById("root");
Modal.setAppElement(rootElement);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <AliveScope>
        
          <App />
          
        </AliveScope>
      </ApolloProvider>
    </Provider>
  </Router>,
  rootElement
)