import { useEffect, useState } from "react";

export const AdminCampaignGift = ({param}) => {

    console.log(param)

     const pageStyle = {
        width: "100vw",
        height: "100vh",
        // padding:"10vmin",
        backgroundImage: `url("https://i.ibb.co/7rW3WDR/Screenshot-2024-12-19-at-1-57-52-AM.png")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        color: "white",
        fontFamily: "'Arial', sans-serif",
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    };

    const headingStyle = {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        height:"100%",
        fontSize: "23vmin",
        textAlign: "center",
        padding: "10px 20px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        margin:0,
    };

    const subheadingStyle = {
        fontSize: "6rem",
        textAlign: "center",
        padding: "8px 16px",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        borderRadius: "8px",
    };

    const [decodedString, setDecodedString]  = useState(null)
    const key = "ihSyadx7CkHU0dUZP0Sj4w86tWTxxJZF";

    function parseDecodedString(decodedString) {
        const [name, number, employee, type] = decodedString.split(", ").map(item => item.trim());
        return { name, number, employee, type };
      }

    function xorDecrypt(encrypted) {
        try {
          const decoded = atob(encrypted); // Decode from Base64
          const keyLength = key.length;
          let result = '';
      
          for (let i = 0; i < decoded.length; i++) {
            result += String.fromCharCode(decoded.charCodeAt(i) ^ key.charCodeAt(i % keyLength));
          }
      
          return result; // Return decrypted result
        } catch (error) {
          return null
        }
      }
      useEffect(() => {
        if(param) {
            let decoded = xorDecrypt(param)

            if(decoded) {
                const parsedObject = parseDecodedString(decoded);
                setDecodedString(parsedObject)
            }
         

        }
      },[param])
      if(decodedString?.number && decodedString.number[0] == "2" && decodedString.number[1] == "0" && decodedString.number[2] == "1") {
        return <div style={pageStyle}>
        <img style={{position:"absolute", bottom:"20px", left:"20px"}} height={"100px"} src="https://i.ibb.co/kBHZpcB/svgviewer-png-output-2.png"/>
        <div style={{position:"absolute", bottom:"20px", right:"20px"}}>
        <div style={{fontSize:"20px", fontWeight:"bold", fontStyle:"italic", alignItems:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>by: {decodedString?.employee || "Mohra" }</div>
            <img  height={"100px"}  src="https://i.ibb.co/1Z69tFy/giftbox.png"/></div>
        
    <div style={{display:"flex", justifyContent:"center", width:"100%", fontSize:"5vmin", height:"7%", minHeight:"100px", padding:"20px"}}> <div style={{margin:"0 20px"}}>Congratulations Mr/s {decodedString?.name} - {decodedString?.number}</div></div>
<h1 style={headingStyle}>
    {decodedString.type}
</h1>
{/* <div style={subheadingStyle}>
    Special Offer
</div> */}
</div>
      } else {
        return <div style={pageStyle}>
        <img style={{position:"absolute", bottom:"20px", left:"20px"}} height={"100px"} src="https://i.ibb.co/kBHZpcB/svgviewer-png-output-2.png"/>
        <div style={{position:"absolute", bottom:"20px", right:"20px"}}>
        <div style={{fontSize:"20px", fontWeight:"bold", fontStyle:"italic", alignItems:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>by: {decodedString?.employee || "Mohra" }</div>
            <img  height={"100px"}  src="https://i.ibb.co/1Z69tFy/giftbox.png"/></div>    {/* <div style={{display:"flex", justifyContent:"center", width:"100%", fontSize:"5vmin", height:"7%", padding:"20px"}}> <div style={{margin:"0 20px"}}>Congratulations {decodedString}</div></div> */}
<h1 style={headingStyle}>
    Good Luck Next Time!
</h1>
{/* <div style={subheadingStyle}>
    Special Offer
</div> */}
</div>
      }

}

